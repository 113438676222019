import AssistanceService from '@/providers/services/assistance'

const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getResponseFromPrompt(context, payload) {
    return await AssistanceService.getResponseFromPrompt(payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};