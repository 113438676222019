<template>
  <div class="card-body">
    <div class="row align-items-center mb-4">
      <div class="col">
        <ScenarioGroupButtons
          :buttons="defaultScenarioButtons"
          :default-value="getEffectifsScenarioTarget"
          @send-type="getScenarioGraphType"
        />
      </div>
      <div class="col-auto">
        <ScenariosSelector
          v-if="!loadingGraph"
          :hidden="scenarioMetadata ? [] : ['target']"
          :displayed-scenarios="['predicted', 'optimal', 'target', 'current']"
          @changed="updateEffectifGlobalSeries"
        />
      </div>
    </div>
    <NoData v-if="!workforceCount" />
    <div v-else>
      <highcharts :options="chartGlobal" />
    </div>
    <div>
      <DataLabelsSwitcher />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatCostType } from '@/providers/helpers/functions';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import ScenarioGroupButtons from '@/components/shared/ScenarioGroupButtons.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';
import NoData from '../../../all/NoData.vue';

const defaultScenarioButtons = [
  {
    name: 'EFF',
    key: 'effective',
    popover: 'Nombre de collaborateurs présents à la date de projection.',
    active: true
  },
  {
    name: 'ETP',
    key: 'fte',
    popover: `Equivalent Temps Plein, Nombre de collaborateurs rapporté
    second au temps de travail effectif à la date de projection.`,
    active: false
  },
  {
    name: 'Coût',
    key: 'budget',
    active: false
  },
]
const chartGlobal = (categories, showDataLabels, scenarioTarget) => ({
  credits: {
    enabled: false,
  },
  title: null,
  xAxis: {
    categories,
  },
  yAxis: {
    title: null,
  },
  series: [],
  legend: {
    enabled: false,
  },
  tooltip: {
    formatter() {
      const { y, x, series } = this;
      const formattedValue = formatCostType(y, scenarioTarget);
      return '<b>' + x + '<br>' + series.name +
              '</b> : <b>' + formattedValue + '</b>';
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: showDataLabels,
        formatter() {
          const value = this.y;
          const formattedValue = formatCostType(value, scenarioTarget);
          return formattedValue
        }
      },
    },
  },
});

export default {
  components: {
    NoData,
    ScenariosSelector,
    DataLabelsSwitcher,
    ScenarioGroupButtons
  },
  props: {
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      currentSelection: [],
      currentLabels: [],
      globalEffectifSeries: [],
      defaultScenarioButtons,
      loadingGraph: false,
      formatCostType,
    };
  },
  computed: {
    ...mapGetters({
      globalEffectifsByScenarioTarget: 'analysis/globalEffectifsByScenarioTarget',
      getEffectifsScenarioTarget: 'effectifs/getEffectifsScenarioTarget'
    }),
    chartGlobal() {
      // eslint-disable-next-line
      const chart = chartGlobal(this.globalEffectifsByScenarioTarget.categories, this.showDataLabels, this.getEffectifsScenarioTarget);
      chart.series = this.globalEffectifSeries;
      return chart;
    }
  },
  watch: {
    globalEffectifsByScenarioTarget: {
      handler() {
        this.loadingGraph = true;
        setTimeout(() => {
          this.loadingGraph = false;
        }, 200)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setEffectifsScenarioTarget: 'analysis/effectifs/setEffectifsScenarioTarget',
    }),
    getScenarioGraphType(type) {
      this.setEffectifsScenarioTarget(type)
    },
    updateEffectifGlobalSeries(scenarios) {
      const { data } = this.globalEffectifsByScenarioTarget;
      this.globalEffectifSeries = [];
      scenarios.forEach((entity) => {
        if (data[entity]) {
          this.globalEffectifSeries.push({
            ...data[entity],
            dataLabels: { color: data[entity].color },
          });
        }
      });
    },
    noDataTab(i) {
      // eslint-disable-next-line
      return !['internalExternal','seniorityGender', 'seniority', 'manager', 'gender', 'contrat', 'age'].includes(
        i.id
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-quantitative {
  ::v-deep .card-header {
    padding: 0 !important;
  }
  ::v-deep .nav-link{
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
