import Vue from 'vue';
import Vuex from 'vuex';

import app from '@/store/modules/app';
import assistance from '@/store/modules/assistance';
import analysis from '@/store/modules/analysis';
import auth from '@/store/modules/auth';
import basic from '@/store/modules/basic';
import competences from '@/store/modules/competences';
import effectifs from '@/store/modules/effectifs';
import hypothesis from '@/store/modules/hypothesis';
import mobility from '@/store/modules/mobility';
import scenarios from '@/store/modules/scenarios';
import user from '@/store/modules/user';
import jobs from '@/store/modules/jobs'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    env: 'collab',
  },
  mutations: {
    setEnv(state, env) {
      state.env = env;
    },
  },
  modules: {
    app,
    assistance,
    analysis,
    auth,
    basic,
    competences,
    effectifs,
    hypothesis,
    mobility,
    scenarios,
    user,
    jobs
  },
});
