/* eslint-disable class-methods-use-this */
import axios from 'axios';

export default class ConfigService {
  constructor() {
    this.apiUrl = process.env.VUE_APP_WSK_BACKEND_URL;
    this.assistantUrl = process.env.VUE_APP_WSK_ASSISTANT_URL;
  }

  get(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  post(url, data = null, config = null) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, config)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  put(url, data = null) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, data)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(url) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
