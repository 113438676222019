<template>
  <div class="mb-5">
    <div v-if="yearStatsAll && !hidePerimeter">
      <div class="container">
        <b-modal v-model="modalPrint" hide-header hide-footer>
          <div class="px-lg-2">
            <div class="mb-3">
              <h5 class="text-uppercase">
                Impression de la synthèse
              </h5>
              <div class="small">
                Sélectionnez les sections à afficher sur la synthèse
              </div>
            </div>
            <div class="mb-3">
              <b-form-checkbox name="mp-check1" value="all">
                Tout sélectionner
              </b-form-checkbox>
            </div>
            <div v-for="(syntese, idx) in syntesePrint" :key="idx" class="mb-3">
              <h6 class="text-uppercase">
                {{ syntese.name }}
                <i class="fas fa-caret-down ml-2" />
              </h6>
              <div v-for="item in syntese.items" :key="item.name" class="mb-2">
                <b-form-checkbox :name="item.name">
                  {{ item.name }}
                </b-form-checkbox>
              </div>
            </div>
            <div class="text-right mt-4">
              <button type="button" class="btn btn-outline-dark text-uppercase">
                Annuler
              </button>
              <button type="button" class="btn btn-primary text-uppercase ml-3">
                Imprimer
              </button>
            </div>
          </div>
        </b-modal>
        <b-row class="my-4">
          <b-col lg="6" xl="6">
            <h5 class="text-uppercase text-primary mb-2">
              Gap analysis
            </h5>
            <div class="d-flex">
              <b-badge
                v-b-tooltip
                pill
                variant="primary"
                class="p-2 text-truncate w-50"
              >
                <span>{{ scenarioName }}</span>
              </b-badge>
              <b-badge
                v-if="customScenario2Metadata"
                v-b-tooltip
                pill
                variant="primary"
                class="ml-2 p-2 text-truncate w-50"
              >
                <span>{{ customScenario2Metadata.label }}</span>
              </b-badge>
              <a href="#" class="d-none p-2 text-secondary"> scénario privé </a>
            </div>
          </b-col>
          <b-col class="d-inline text-lg-right">
            <a
              href="#"
              class="btn btn-primary rounded-50 ml-3"
              @click.prevent="openAiChat"
            >
              <i class="fas fa-robot mr-2" />
              Assistant IA
            </a>
            <beautiful-chat
              class="ia-chat"
              :participants="participants"
              :onMessageWasSent="onMessageWasSent"
              :messageList="messageList"
              :newMessagesCount="newMessagesCount"
              :isOpen="isChatOpen"
              :close="closeChat"
              :open="openAiChat"
              :showEmoji="false"
              :showFile="false"
              :showEdition="true"
              :showDeletion="true"
              :showTypingIndicator="showTypingIndicator"
              :showLauncher="false"
              :showCloseButton="true"
              :colors="colors"
              :alwaysScrollToBottom="alwaysScrollToBottom"
              :disableUserListToggle="false"
              :messageStyling="messageStyling"
              @onType="handleOnType"
              @edit="editMessage" 
            >
              <template v-slot:header>
                <div class="ia-chat__header">
                  <h4 class="ia-chat__header-title">Strategic Workforce Planning AI</h4>
                  <i class="ia-chat__header-close-btn fal fa-times-circle" @click="closeChat"></i>
                </div>
              </template>
            </beautiful-chat>
          </b-col>
          <!-- <b-col>
            <a
              href="#"
              class="btn btn-outline-primary rounded-50 text-uppercase disabled"
              @click.prevent="modalPrint = true"
            >
              Générer une synthèse
            </a>
            <a
              href="#"
              class="btn btn-primary rounded-50 text-uppercase ml-3 disabled"
              @click.prevent=""
            >
              <i class="fal fa-steering-wheel mr-2" />
              Simulateur de faisabilité
            </a>
          </b-col> -->
        </b-row>
        <div class="mb-4">
          <div class="card analysis-card">
            <div
              v-b-toggle.collapse-perimetre
              class="card-header bg-secondary text-white py-2"
              @click="activePerimetreFilter = !activePerimetreFilter"
            >
              <span class="position-relative mr-3">
                <span class="text-uppercase font-weight-bold"> Périmètre d'analyse </span>
                <span id="p-analyse" class="ml-1 text-white" style="position: relative; top: -5px">
                  <i class="fal fa-question-circle" />
                </span>
                <b-popover target="p-analyse" triggers="hover">
                  Raffiner le périmètre désiré. Par défaut, le périmètre défini durant la création du
                  scénario choisi est appliqué.
                </b-popover>
              </span>
              <i v-if="!activePerimetreFilter" class="fas fa-caret-down" />
              <i v-else class="fas fa-caret-up" />
            </div>
            <b-collapse id="collapse-perimetre">
              <div class="card-body px-lg-5">
                <form>
                  <div class="row">
                    <div class="col-sm-6">
                      <b-form-group label-for="input-one">
                        <div slot="label">
                          <b>Structure de coût</b>
                        </div>
                        <filter-selector
                          key-option="costCentres"
                          :object-value="false"
                          :selection="costCentreSelection"
                          :allowed="costCentreSelection"
                          :selection-disabled="true"
                          @selected="costCentreFilter"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group label-for="input-one">
                        <div slot="label">
                          <b>Entités</b>
                        </div>
                        <unit-selector
                          :selection="unitSelection"
                          :selection-disabled="false"
                          :allowed="unitSelection"
                          :object-value="false"
                          @selected="unitFilter"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group label-for="input-one">
                        <div slot="label">
                          <b>Zones géographiques</b>
                        </div>
                        <filter-tree-selector
                          :options="siteTree"
                          :object-value="false"
                          :selection="siteSelection"
                          :selection-disabled="false"
                          :allowed="siteSelection"
                          @selected="siteFilter"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group label-for="input-one">
                        <div slot="label">
                          <b>Compétences</b>
                          <span
                            id="p-analyse-competences"
                            class="ml-1"
                            style="position: relative; top: -5px"
                          >
                            <i class="fal fa-question-circle" />
                          </span>
                          <b-popover target="p-analyse-competences" triggers="hover">
                            Filtrer sur les collaborateurs qui maîtrisent des compétences.<br>
                            Si vous sélectionnez une catégorie de compétences, vous obtenez les
                            collaborateurs qui maîtrisent au moins une compétence de la catégorie.
                          </b-popover>
                        </div>
                        <skill-selector
                          :selection="skillSelection"
                          :selection-disabled="false"
                          :allowed="skillSelection"
                          :object-value="false"
                          :exact="true"
                          @selected="skillFilter"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group label-for="input-one">
                        <div slot="label">
                          <b>Métiers</b>
                        </div>
                        <job-selector
                          :selection="jobSelection"
                          :selection-disabled="false"
                          :allowed="jobSelection"
                          :object-value="false"
                          @selected="jobFilter"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-sm-6">
                      <b-form-group label-for="input-one">
                        <div slot="label">
                          <b>Filtrer par contrat</b>
                        </div>
                        <type-contract-selector
                          key-option="contractType"
                          :object-value="false"
                          :selection="contractTypeSelection"
                          :allowed="contractTypeSelection"
                          :selection-disabled="true"
                          @selected="contractTypeFilter"
                        />
                      </b-form-group>
                    </div>
                    <div class="container">
                      <div class="row justify-content-end">
                        <div class="col-xs-12 col-sm-6 col-md-4">
                          <button
                            type="button"
                            :disabled="(disableSearchButton && !activeFilter) || loading"
                            class="btn btn-block bg-secondary text-white"
                            @click="updatePerimeter"
                          >
                            <b-spinner v-if="loading" small />
                            <span class="ml-1">Rechercher</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
        </div>
        <b-overlay :show="loading" class="mb-4">
          <div class="card analysis-card">
            <div class="card-header py-2 text-white bg-primary">
              <div class="position-relative">
                <span class="text-uppercase font-weight-bold"> Variable d'analyse principale </span>
                <span
                  id="main-analysis"
                  class="ml-1 text-white"
                  style="position: relative; top: -5px"
                >
                  <i class="fal fa-question-circle" />
                </span>
                <b-popover target="main-analysis" triggers="hover">
                  Modifier la date à laquelle vous souhaitez visualiser la projection. Changer la
                  projection pour qu'elle soit en ETP ou en effectifs.
                </b-popover>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between align-items-center">
                <div class="col-md-4">
                  <b-form-group class="mb-0" label-for="input-a-principale">
                    <b-form-select
                      id="input-a-principale"
                      v-model="gFilters.type"
                      :options="entityOptions"
                      required
                    />
                  </b-form-group>
                </div>
                <div class="col d-flex justify-content-center">
                  <ScenarioGroupButtons
                    :buttons="defaultScenarioButtons"
                    :default-value="scenarioTarget"
                    @send-type="getScenarioType"
                  />
                </div>
                <div class="col text-left mr-5">
                  <h6 class="mb-2">
                    Période de projection:
                    <span
                      id="projection-date-range"
                      class="ml-1 text-dark"
                      style="position: relative; top: -5px"
                    >
                      <i class="fal fa-question-circle" />
                    </span>
                    <b-popover target="projection-date-range" triggers="hover">
                      Positionner le curseur à la date à laquelle vous souhaitez étudier la population
                    </b-popover>
                  </h6>
                  <div class="mr-2">
                    <vue-slider v-model="sliderFilter" v-bind="sliderOptions" tooltip="none" />
                  </div>
                </div>
              </div>
              <div class="small text-muted mt-3">
                Choisir le champ que vous souhaitez étudier. Cette variable peut être changée en cours
                de route.
              </div>
            </div>
          </div>
        </b-overlay>
        <b-overlay :show="loading" class="mb-4">
          <div class="d-flex justify-content-between mb-2">
            <h6 v-if="gFilters.year" class="text-uppercase font-weight-bold ml-2">
              INDICATEURS ({{ gFilters.year }})
            </h6>
            <b-form-radio-group v-model="currentScenario" size="sm" :options="scenariosOptions" />
          </div>
          <div class="form-row">
            <div v-for="(i, idx) in indicaters" :key="idx" class="col">
              <CardIndicater :item="i" :force-no-value="customFilter.forceNoValue" />
            </div>
          </div>
        </b-overlay>
      </div>
      <template v-if="reloadTabs">
        <div class="mb-5 container">
          <div class="mb-5">
            <b-tabs
              :key="key"
              fill
              nav-class="tab-style-line"
              lazy
            >
              <template #tabs-end>
                <b-nav-item
                  v-for="(tab, idx) in tabsGlobal"
                  :key="tab.id"
                  href="#"
                  :class="{ active: idx === activeIdx }"
                  :disabled="tabsLoader[tab.key]"
                  @click.prevent="activeIdx = idx"
                >
                  <b-spinner v-if="tabsLoader[tab.key]" small class="align-middle mr-2" />
                  <i v-if="idx > 2 && idx < 4" />
                  <span :class="tabsLoader[tab.key] ? 'text-muted' : ''">{{ tab.label }}</span>
                </b-nav-item>
              </template>
            </b-tabs>
          </div>
        </div>
        <b-overlay
          v-if="workforce"
          :key="workforce.value"
          :show="loading"
          class="mb-4"
        >
        <div class="container">
          <TabEffectifs
            v-if="activeIdx === 0 && yearStatsAll.graphData"
            :key="workforce.value"
            :workforce-count="workforce.value"
            :scenario-metadata="customScenarioMetadata"
            :perimeter="customFilter.filter"
          />
          <TabCompetences
            v-if="activeIdx === 1"
            :key="workforce.value"
            :workforce-count="workforce.value"
            :scenario-metadata="customScenarioMetadata"
            :default-scenario="defaultScenario"
          />
          <TabMobilite
            v-if="activeIdx === 2"
            :key="workforce.value"
            :workforce-count="workforce.value"
            :default-scenario="defaultScenario"
            :scenario-metadata="customScenarioMetadata"
          />
          <!-- <TabLocalization
            v-if="activeIdx === 3"
            :key="workforce.value"
            :workforce-count="workforce.value"
          /> -->
          <TabCollaborateurs
            v-if="activeIdx === 3"
            :key="workforce.value"
            :perimeter="customFilter.filter"
            :workforce-count="workforce.value"
            :scenario-metadata="customScenarioMetadata"
            :default-scenario="defaultScenario"
            :years="yearsStats"
            :staff-data="staffData.attritionData"
            :global-filter="filter"
          />
        </div>
        <div class="container-fluid">
          <TabHypothesis
            v-if="activeIdx === 4"
            :key="workforce.value"
            :workforce-count="workforce.value"
            :scenario-metadata="customScenarioMetadata"
            :default-scenario="defaultScenario"
            :perimeter="customFilter.filter"
            :years="yearsStats"
            :rates="rates"
            :comparing="isComparing"
            :global-filter="filter"
          />
        </div>

        </b-overlay>
      </template>
      <div class="d-none text-right container">
        <button type="button" class="btn btn-outline-primary text-uppercase">
          Télécharger PDF
          <i class="fal fa-arrow-to-bottom ml-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import ScenarioGroupButtons from '@/components/shared/ScenarioGroupButtons.vue';
import CardIndicater from '@/components/all/CardIndicater.vue';
import TabEffectifs from '@/components/analysis-tabs/TabEffectifs.vue';
import TabCompetences from '@/components/analysis-tabs/TabCompetences.vue';
import TabMobilite from '@/components/analysis-tabs/TabMobilite.vue';
// import TabLocalization from '@/components/analysis-tabs/TabLocalization.vue';
import TabCollaborateurs from '@/components/analysis-tabs/TabCollaborateurs.vue';
import TabHypothesis from '@/components/analysis-tabs/TabHypothesis.vue';
import JobSelector from '@/components/selectors/JobSelector.vue';
import SkillSelector from '@/components/selectors/SkillSelector.vue';
import UnitSelector from '@/components/selectors/UnitSelector.vue';
import FilterSelector from '@/components/selectors/FilterSelector.vue';
import FilterTreeSelector from '@/components/selectors/FilterTreeSelector.vue';
import TypeContractSelector from '@/components/selectors/TypeContractSelector.vue';
import { appendPredictionData, getCustomFilterFrom } from '@/services/helpers'
import Chat from 'vue-beautiful-chat'

const syntesePrint = [
  {
    name: 'Effectifs',
    items: [
      { name: 'Sexe' },
      { name: 'Statut' },
      { name: 'Temps de travail' },
      { name: 'Type de contrat' },
      { name: 'Âge' },
      { name: 'Ancienneté' },
      { name: 'Niveau hiérarchique' },
      { name: 'Niveau de maîtrise' },
    ],
  },
  {
    name: 'Compétences',
    items: [
      { name: 'Répartition des compétences' },
      { name: 'Barrettes de compétences' },
      { name: 'Représentations graphiques' },
      { name: 'Tableau comparatif' },
    ],
  },
  {
    name: 'Mobilité',
    items: [{ name: 'Resumé des mobilités' }],
  },
  // {
  //   name: 'Localisation',
  //   items: [{ name: 'Résumé des localisations' }],
  // },
  {
    name: 'Entrées/Sorties',
    items: [{ name: 'Collaborateurs entrants' }, { name: 'Collaborateurs sortants' }],
  },
  {
    name: 'HYPOTHÈSES',
    items: [
      { name: 'Synthèse' },
      { name: 'Hypothèses spécifiques' },
      { name: 'Hypothèses de compétences' },
    ],
  },
];

const indicatorsKeys = {
  workforce: {
    name: 'Effectifs',
    title: `Le nombre de personnes du périmètre d’analyse
    projeté à la date de projection sélectionnée`,
    fallback: 'Pas de données',
    allowZero: true,
  },
  middleAge: {
    name: 'Age moyen',
    title: `La moyenne d’âge des effectifs du périmètre d’analyse à la date de projection
    sélectionnée`,
    fallback: 'Pas de données',
    allowZero: false,
  },
  recruitmentRate: {
    name: 'Taux de recrutement',
    title: `Le nombre de personnes recrutées du périmètre d’analyse sur la période
    cumulée de projection par rapport à l’effectif actuel`,
    fallback: 'Pas de données',
    allowZero: false,
  },
  averageDuration: {
    name: 'Durée moyenne dans le poste',
    title: 'La durée moyenne dans le poste des effectifs à la date de projection sélectionnée',
    fallback: 'Pas de données',
    allowZero: false,
  },
  averageRetirementAge: {
    name: 'Age moyen de départ en retraite',
    title: `L’âge moyen des effectifs du périmètre d’analyse qui partent à la retraite
    durant l’année de projection sélectionnée`,
    fallback: 'Aucun départ à la retraite',
    allowZero: false,
  },
  turnover: {
    name: 'Turnover',
    title:
      `Calculé selon la formule suivante :
       [(Nombre de départs sur l’Année N + Nombre d’arrivées sur l’Année N) / 2] /
       [moyenne (Effectif au 31/12 de l’Année N-1 ; Effectif au 31/12 de l’Année N)] x 100`,
    fallback: 'Pas de données',
    allowZero: false,
  },
};

const entityLabels = {
  Unit: 'Entités',
  Job: 'Métiers',
  CostCentre: 'Structure de coûts',
  Organization: 'Organisations',
  Site: 'Zones géographiques',
};

// eslint-disable-next-line max-len
const formattedIndicators = (indicators) =>
  indicators.map((kpi) => ({ ...kpi, ...indicatorsKeys[kpi.label] }));

const sliderOptions = (years) => ({
  min: 0,
  max: years.length - 1,
  tooltip: 'hover',
  marks(val) {
    return {
      label: `Au 31/12/${years[val]}`,
    };
  }
});

export default {
  components: {
    CardIndicater,
    TabEffectifs,
    TabCompetences,
    TabMobilite,
    // TabLocalization,
    TabCollaborateurs,
    TabHypothesis,
    JobSelector,
    UnitSelector,
    SkillSelector,
    FilterSelector,
    FilterTreeSelector,
    TypeContractSelector,
    ScenarioGroupButtons,
  },
  data() {
    return {
      key: 0,
      defaultScenarioButtons:[
        {
          name: 'EFF',
          key: 'effective',
          popover: 'Nombre de collaborateurs présents à la date de projection.',
          active: true
        },
        {
          name: 'ETP',
          key: 'fte',
          popover: `Equivalent Temps Plein, Nombre de collaborateurs rapporté
          second au temps de travail effectif à la date de projection.`,
          active: false
        },
        {
          name: 'Coût',
          key: 'budget',
          active: false
        },
      ],
      hidePerimeter: false,
      gFilters: {},
      selectedCheck: 'first',
      syntesePrint,
      activeIdx: 0,
      modalPrint: false,
      sliderFilter: 0,
      activePerimetreFilter: false,
      filter: {},
      activeFilter: false,
      unitSelected: [],
      skillSelected: [],
      jobSelected: [],
      costCentreSelected: [],
      contractTypeSelected: [],
      siteSelected: [],
      organizationSelected: [],
      reloadTabs: true,
      customScenarioMetadata: null,
      customScenario2Metadata: null,
      currentScenario: 'predicted',
      defaultScenario: '',
      defaultFilter: {
        units: [],
        skills: [],
        jobs: [],
        costCentres: [],
        contractTypes: [],
        sites: [],
        organizations: [],
      },
      forceNoValue: false,
      rates: {},
      tabsLoader: {
        people: false,
        skills: false,
        mobility: false,
        hypothesis: false,
        staff: false
      },
      staffData: null,
      loading: false,
      participants: [
        {
          id: 'swpAssistant',
          name: 'SWPAssistant',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        },
        {
          id: 'me',
          name: 'User',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ],
      messageList: [
          /* { type: 'text', author: `me`, data: { text: `Say yes!` } },
          { type: 'text', author: `swpAssistant`, data: { text: `No.` } } */
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: '',
      colors: {
        header: {
          bg: '#ffffff',
          text: '#2E2E2E'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false,
      messageStyling: true
    };
  },
  computed: {
    isComparing () {
      return !!this.$route?.query?.id2
    },
    comparingFirstScenario () {
      return {
        ...this.customScenarioMetadata,
        scenarioType: 'target'
      }
    },
    comparingSecondScenario () {
      return {
        ...this.customScenario2Metadata,
        scenarioType: 'optimal'
      }
    },
    tabsGlobal () {
      return [
        { id: 0, label: 'Effectifs/Coûts', key: 'people' },
        { id: 1, label: 'Compétences', key: 'skills' },
        { id: 2, label: 'Mobilité', key: 'mobility' },
        // { id: 3, label: 'Localisation', key: 'localization' },
        { id: 3, label: 'Entrées/Sorties', key: 'staff' },
        {
          id: 4,
          label: this.customScenarioMetadata ? 'Hypothèses' : 'Tableau détaillé',
          key: 'hypothesis'
        }
      ];
    },
    scenarioName() {
      if (this.customScenarioMetadata && this.customScenarioMetadata.label) {
        return this.customScenarioMetadata.label;
      }
      return this.currentUser.scenariocreator ? 'Scénario par défaut' : 'Tendances';
    },
    customFilter() {
      const metadataFilter = this.customScenarioMetadata?.filter || {};
      const userFilter = this.currentUser?.filter || {};
      // for metadata and user filters, fill with each possibilities for further comparison
      // ex: JobCategory100 =
      // Main Category: JobCategory100 +
      // First Subcategory and its children: JobCategory40 + ComJob345 + ComJob567 +
      // Second Subcategory and its children: JobCategory45 + ComJob745
      return getCustomFilterFrom(userFilter, metadataFilter, this.defaultFilter, this);
    },
    jobSelection() {
      return this.customFilter?.filter?.jobs || [];
    },
    skillSelection() {
      return this.customFilter?.filter?.skills || [];
    },
    siteSelection() {
      return this.customFilter?.filter?.sites || [];
    },
    unitSelection() {
      return this.customFilter?.filter?.units || [];
    },
    costCentreSelection() {
      return this.customFilter?.filter?.costCentres || [];
    },
    contractTypeSelection() {
      return this.customFilter?.filter?.contractTypes || [];
    },
    entityOptions() {
      return this.entityTypes
        .filter((e) => ['Job', 'Unit', 'CostCentre', 'Site'].includes(e))
        .map((e) => ({
          value: e,
          text: entityLabels[e],
        }));
    },
    // eslint-disable-next-line vue/return-in-computed-property
    yearsStats() {
      if (this.yearPredictedStatsMap) {
        return Object.keys(this.yearPredictedStatsMap);
      }
    },
    sliderOptions() {
      return sliderOptions(this.yearsStats);
    },
    indicaters() {
      // eslint-disable-next-line max-len
      return (
        this.yearStatsAll &&
        this.yearStatsAll.basicStats &&
        this.yearStatsAll.basicStats[this.currentScenario] &&
        formattedIndicators(this.yearStatsAll.basicStats[this.currentScenario])
      );
    },
    workforce() {
      if (this.customFilter.forceNoValue) {
        return {
          value: 0,
        };
      }
      return (
        this.yearStatsAll &&
        this.yearStatsAll.basicStats &&
        this.yearStatsAll.basicStats[this.currentScenario] &&
        this.yearStatsAll.basicStats[this.currentScenario].find(
          (item) => item.label === 'workforce'
        )
      );
    },
    disableSearchButton() {
      return (
        !this.costCentreSelected?.length &&
        !this.contractTypeSelected?.length &&
        !this.organizationSelected?.length &&
        !this.unitSelected?.length &&
        !this.siteSelected?.length &&
        !this.skillSelected?.length &&
        !this.jobSelected?.length
      );
    },
    scenariosOptions () {
      return this.scenariosArray.map(scenario => {
        if (this.isComparing) {
          if (scenario.value === 'target') {
            scenario.text = this.comparingFirstScenario.label.substr(0, 50)
          }
          if (scenario.value === 'optimal') {
            scenario.text = this.comparingSecondScenario.label.substr(0, 50)
          }
        }
        return scenario
      })
    },
    ...mapState('analysis', {
      entityTypes: (state) => state.allData.entityTypes,
      yearPredictedStatsMap: (state) => state.allData.yearPredictedStatsMap,
      filters: (state) => state.filters,
    }),
    ...mapGetters({
      currentUser: 'auth/currentUser',
      yearStatsAll: 'analysis/yearStatsAll',
      scenarios: 'auth/basic/getActiveScenariosIds',
      scenariosArray: 'auth/basic/getScenariosWithoutCurrent',
      scenarioTarget: 'auth/basic/getScenarioTarget',
    }),
  },
  watch: {
    gFilters: {
      async handler(curr) {
        this.reloadTabs = false;
        try {
          // check if type or year changed

          // Reset Graph values
          this.setGender(['Global']);
          this.setContrat(['Global']);
          this.setAge('Global');
          this.setSeniority(['Global']);
          this.setManager(['Global']);
          this.setSeniorityGender('Global');
          this.setInternalExternal(['Global']);

          this.setFilter({ key: 'type', val: curr.type });
        } catch (err) {
          console.error('error gFilter watcher', err);
        } finally {
          this.reloadTabs = true;
        }
      },
      deep: true,
    },
    sliderFilter: {
      handler(curr) {
        if (this.yearsStats) {
          let val = this.yearsStats[curr];
          this.setFilter({ key: 'year', val });
          val = null;
        }
      },
      immediate: false,
    },
  },
  async mounted() {
    this.initView();
  },
  async created() {
    this.hidePerimeter = true;
    if (this.$route.params.id && this.$route.query.default === '0') {
      this.updateCurrentScenario('target');
      this.addActiveScenarios(['target']);
      this.defaultScenario = 'target';
    } else {
      this.updateCurrentScenario('predicted');
      this.setDisabledScenarios(['target']);
    }
  },
  methods: {
    getScenarioType(type){
      this.sentScenarioTarget(type)
      this.initView()
    },
    async initView() {
      try {
        this.resetFilters();
        this.gFilters = this.filters;
        this.setLoaderStatus(true);
        if (this.$route.params.id && this.$route.query.default === '0') {
          // add case when id2 is provided (comparison mode)
          await this.fetchScenario(this.$route.params.id, this.$route?.query?.id2);
          this.sliderFilter = 2;
        } else {
          // eslint-disable-next-line
          await this.fetchAnalysisTabsData(this.customFilter.filter, null, null, this.scenarioTarget)
          this.setFilter({ key: 'type', val: this.entityTypes[0] });
          this.setFilter({ key: 'year', val: this.yearsStats[0] });
        }
        this.hidePerimeter = false;
      } catch (err) {
        console.log('error', err);
      } finally {
        this.setLoaderStatus(false);
      }
    },
    updateCurrentScenario(scenario) {
      this.currentScenario = scenario;
    },
    resetFilters() {
      this.filter = { ...this.defaultFilter };
    },
    unitFilter(units) {
      this.unitSelected = units;
      this.filter.units = this.unitSelected;
    },
    jobFilter(jobs) {
      this.jobSelected = jobs;
      this.filter.jobs = this.jobSelected;
    },
    skillFilter(skills) {
      this.skillSelected = skills;
      this.filter.skills = this.skillSelected;
    },
    costCentreFilter(costCentres) {
      this.costCentreSelected = costCentres;
      this.filter.costCentres = this.costCentreSelected;
    },
    contractTypeFilter(costCentres) {
      this.contractTypeSelected = costCentres;
      this.filter.contractTypes = this.contractTypeSelected;
    },
    siteFilter(sites) {
      this.siteSelected = sites;
      this.filter.sites = this.siteSelected;
    },
    mergeFilters (filters) {
      const newFilters = { ...this.customFilter.filter }
      Object.keys(filters).forEach(key => {
        newFilters[key] = filters[key]
      })
      return newFilters
    },
    // DEPRECATED
    async searchByFilter() {
      console.log('using deprecated function: searchByFilter')
      this.reloadTabs = false;
      this.setLoaderStatus(true);
      const filter = { ...this.filter };
      // eslint-disable-next-line
      for (const ft in filter) if (!filter[ft]?.length) delete filter[ft];
      this.activeFilter = !!Object.keys(filter).length;
      try {
        if (this.$route.params.id && this.$route.query.default === '0') {
          await this.updateScenarioData({ id: this.$route.params.id, filter });
        } else {
          await this.loadDataByFilter(filter);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.gFilters = this.filters;
        this.setLoaderStatus(false);
        this.reloadTabs = true;
      }
    },
    async updatePerimeter () {
      const filter = { ...this.filter };
      // eslint-disable-next-line
      for (const ft in filter) if (!filter[ft]?.length) delete filter[ft];
      this.activeFilter = !!Object.keys(filter).length;
      if (this.$route.params.id && this.$route.query.default === '0') {
        await this.fetchScenario(this.$route.params.id, this.$route?.query?.id2, filter)
      } else {
        await this.fetchAnalysisTabsData(filter, null, null, this.scenarioTarget, null, () => {
          this.gFilters = this.filters
          this.key++
          this.$forceUpdate()
        })
      }
    },
    async fetchAnalysisTabsData (filters, id = null, id2 = null, type, startCb = null, endCb) {
      try {
        // init vars
        this.loading = true
        this.tabsLoader.people = true
        this.tabsLoader.hypothesis = true
        this.tabsLoader.mobility = true
        this.tabsLoader.skills = true
        this.tabsLoader.staff = true

        // 1. execute basic request synchronously
        let data = await this.getScenarioBasicResults({ id, id2, filters, type })
        await this.setAllData(data)
        let dataTypeAll = await this.getScenarioBasicResults({ id, id2, filters, type: 'all' })
        await this.setAllDataByScenarioTarget(dataTypeAll)
        await this.setAllDataComparativeByScenarioTarget({
          Global: dataTypeAll
        })
        // set filters
        if (!filters) {
          this.setFilter({ key: 'type', val: data.entityTypes[0] })
        } else {
          // we define default values to display default dataset on UI
          this.setFilter({ key: 'type', val: this.filters.type });
          this.setFilter({ key: 'year', val: this.filters.year });
        }

        if (startCb) await startCb()

        // load data asynchronously and append to Prediction Data
        this.getScenarioGraphResults({ id, id2, filters, type }).then((graph) => {
          data = appendPredictionData(data, graph, 'graphData')
          this.setAllData(data)
          this.tabsLoader.people = false
          if (endCb) endCb()
        })
        this.getScenarioTableResults({ id, id2, filters, type}).then((table) => {
          data = appendPredictionData(data, table, 'tableData')
          this.setAllData(data)
          this.tabsLoader.hypothesis = false
          if (endCb) endCb()
        })
        this.getScenarioTableResults({ id, id2, filters, type: 'all'}).then((table) => {
          this.setAllDataTableByScenarioTarget(table)
        })
        this.getScenarioCompetencyResults({ id, id2, filters }).then((competency) => {
          data = appendPredictionData(data, competency, 'competencyData')
          this.setAllData(data)
          this.tabsLoader.skills = false
          if (endCb) endCb()
        })
        this.getScenarioMobilityResults({ id, id2, filters }).then((mobility) => {
          data = appendPredictionData(data, mobility, 'mobilityData')
          this.setAllData(data)
          this.tabsLoader.mobility = false
          if (endCb) endCb()
        })
        this.getScenarioStaffResults({ id, id2, filters }).then((staff) => {
          this.staffData = staff
          this.tabsLoader.staff = false
        })
      } catch (err) {
        console.log('err', err);
      } finally {
        this.loading = false;
      }
    },
    async fetchScenario(id, id2 = null, filters = {}) {
      this.customScenarioMetadata = await this.fetchScenarioById({ id })
      if (id2) {
        this.customScenario2Metadata = await this.fetchScenarioById({id: id2})
      }
      // load filter for this scenario
      await this.loadBasicData({ id, id2 }).catch(() => {});
      if (id && id2) {
        this.switchScenariosToComparisonMode([
          this.comparingFirstScenario.label,
          this.comparingSecondScenario.label
        ])
      } else {
        this.switchScenariosToNormalMode()
      }
      const f = this.mergeFilters(filters)
      await this.fetchAnalysisTabsData(f, id, id2, this.scenarioTarget, async () => {
        this.rates = await this.fetchGenericRates({
          type: this.customScenarioMetadata.scenario,
          filters: f
        })
        this.setFilter({ key: 'type', val: this.customScenarioMetadata.filterAttribute })
        this.setFilter({ key: 'year', val: this.yearsStats[0] })
      })
    },
    ...mapMutations({
      setAllData: 'analysis/setAllData',
      setAllDataByScenarioTarget: 'analysis/setAllDataByScenarioTarget',
      setAllDataTableByScenarioTarget: 'analysis/setAllDataTableByScenarioTarget',
      setAllDataComparativeByScenarioTarget: 'analysis/setAllDataComparativeByScenarioTarget',
      setDisabledScenarios: 'auth/basic/setDisabledScenarios',
      setEnabledScenarios: 'auth/basic/setEnabledScenarios',
      addActiveScenarios: 'auth/basic/addActiveScenarios',
      switchScenariosToComparisonMode: 'auth/basic/switchScenariosToComparisonMode',
      switchScenariosToNormalMode: 'auth/basic/switchScenariosToNormalMode'
    }),
    ...mapActions({
      getScenarioGraphResults: 'analysis/loadScenarioGraphData',
      getScenarioBasicResults: 'analysis/loadScenarioBasicData',
      getScenarioTableResults: 'analysis/loadScenarioTableData',
      getScenarioMobilityResults: 'analysis/loadScenarioMobilityData',
      getScenarioCompetencyResults: 'analysis/loadScenarioCompetencyData',
      getScenarioStaffResults: 'analysis/loadScenarioAttritionData',
      setGender: 'analysis/effectifs/setGenderSelects',
      setContrat: 'analysis/effectifs/setContratSelects',
      setAge: 'analysis/effectifs/setAgeSelect',
      setSeniority: 'analysis/effectifs/setSenioritySelects',
      setManager: 'analysis/effectifs/setManagerSelects',
      setSeniorityGender: 'analysis/effectifs/setSeniorityGenderSelects',
      //New mapAction for setInternalExternalSelects
      setInternalExternal: 'analysis/effectifs/setInternalExternalSelects',
      setLoaderStatus: 'app/setLoaderStatus',
      loadDataByFilter: 'analysis/loadDataByFilter',
      setFilter: 'analysis/setFilter',
      updateScenarioData: 'analysis/updateScenarioData',
      fetchScenarioById: 'scenarios/fetchScenarioById',
      loadScenarioData: 'analysis/loadScenarioData',
      fetchGenericRates: 'scenarios/fetchGenericRates',
      loadBasicData: 'auth/basic/loadBasicData',
      sentScenarioTarget: 'basic/sentScenarioTarget',
      getResponseFromPrompt: 'assistance/getResponseFromPrompt',
    }),
    async onMessageWasSent (message) {
      // called when the user sends a message
      this.showTypingIndicator = '...'
      this.messageList = [ ...this.messageList, message ];
      if (this.messageList.length === 0) {
        setTimeout(() => {
          this.createEmptyMessage()
        }, 100)
      } else {
        setTimeout(() => {
          this.destroyEmptyMessage()
        }, 100)
      }
      await this.getAssistanceResponse(message);
      this.showTypingIndicator = ''
    },
    async getAssistanceResponse(message) {
      try {
        const assistanceTextReponse = await this.getResponseFromPrompt({
          sentence: message.data.text
        });
        const assistanceObjectResponse = {
          author: 'swpAssistant',
          type: 'text',
          data: { text: assistanceTextReponse }
        }
        this.messageList = [ ...this.messageList, assistanceObjectResponse];
        if (this.messageList.length === 0) {
        setTimeout(() => {
            this.createEmptyMessage()
          }, 100)
        } else {
          setTimeout(() => {
            this.destroyEmptyMessage()
          }, 100)
        }
      } catch (e) {
        console.error('AI chat assistant error: ', e);
      }
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    createEmptyMessage() {
      const messageListElement = document.querySelector('.sc-message-list')
      const existEmptyMessageElement = document.querySelector('.ia-chat__empty-message')
      if (!existEmptyMessageElement) {
        const emptyMessageElement = document.createElement("div");
        emptyMessageElement.classList.add("ia-chat__empty-message"); 
        const emptyMessageIconElement = document.createElement("span");
        emptyMessageIconElement.textContent = '✨'
        const emptyMessageTitleElement = document.createElement("h5");
        emptyMessageTitleElement.textContent = 'Comment puis-je vous aider ?'
        const emptyMessageDescriptionElement = document.createElement("p");
        // eslint-disable-next-line
        emptyMessageDescriptionElement.textContent = 'Je vous assiste dans votre démarche SWP, en guidant vos analyses et projections.'
        const authorMessageDescriptionElement = document.createElement("p");
        authorMessageDescriptionElement.textContent = 'par Neobrain'
        emptyMessageElement.appendChild(emptyMessageIconElement)
        emptyMessageElement.appendChild(emptyMessageTitleElement)
        emptyMessageElement.appendChild(emptyMessageDescriptionElement)
        emptyMessageElement.appendChild(authorMessageDescriptionElement)
        messageListElement.appendChild(emptyMessageElement)
      } 
    },
    destroyEmptyMessage() {
      const messageListElement = document.querySelector('.sc-message-list')
      const emptyMessageElement = document.querySelector('.ia-chat__empty-message')
      // eslint-disable-next-line
      if (messageListElement && emptyMessageElement) messageListElement.removeChild(emptyMessageElement)
    },
    openAiChat() {
      this.isChatOpen = true
      this.newMessagesCount = 0
      if (this.messageList.length === 0) {
        setTimeout(() => {
          this.createEmptyMessage()
        }, 100)
      } else {
        setTimeout(() => {
          this.destroyEmptyMessage()
        }, 100)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.analysis-card {
  border-radius: 10px;
  .not-collapsed {
    transition: border-radius 750ms;
    border-radius: 10px 10px 0 0;
  }
  .collapsed {
    transition: border-radius 750ms;
    border-radius: 10px;
  }
}
.ia-chat {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    width: 100%;
  }
  &__header-title {
    font-size: 16px;
    margin: 0
  }
  &__header-close-btn {
    cursor: pointer;
  }
}
::v-deep .sc-chat-window{
  z-index: 10;
  border: 1px solid #4b008240;
  width: 500px !important;
  height: 750px !important;
  max-height: 790px !important;
  position: absolute !important;
  top: 40px
} 

::v-deep .ia-chat__empty-message {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a0a0a0;

  span {
    font-size: 50px;
  }
}

::v-deep .sc-header{
  min-height: 45px;
  align-items: center;
} 

::v-deep .sc-launcher{
  opacity: 0;
  background: #581374 !important;
  position: static !important;
  // z-index: 10;
}

::v-deep .sc-user-input--text {
  width: 430px !important;
}

::v-deep .sc-user-input--button-icon-wrapper {
  svg {
    color: #581374 !important;
  }
}

::v-deep .sc-message {
  width: 450px;
}

::v-deep .sc-message--text {
  padding: 5px 20px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  max-width: 265px;
  -webkit-font-smoothing: subpixel-antialiased;
}

::v-deep .sc-message--avatar {
  display: none;
}

::v-deep .sc-user-input--text:empty:before {
  content: 'Rédigez une instruction...';
}

::v-deep .sc-header--close-button {
  display: none;
}
</style>
